



































import { Component, Vue } from 'vue-property-decorator'
import LayoutSideBar from '@/layouts/LayoutSideBar.vue'

@Component({
  components: {
    LayoutSideBar
  }
})
export default class ChangePasswordResult extends Vue {
  public result: string = 'success'

  created() {
    if (this.$route.query.status) {
      if (this.$route.query.status === 'true') {
        this.result = 'success'
      } else {
        this.result = 'error'
      }
    } else {
      this.$router.push({ name: 'change-password' })
    }
  }

  public backToHome() {
    this.$router.push({ name: 'home' })
  }
}
